import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import store from "../store/index";
//import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requireAuth: false},
  },
  {
    path: "/Photos",
    name: "Photos",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Photos.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/Register",
    name: "Register",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Register.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/Success",
    name: "Success",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Success.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/TermsAndCond",
    name: "TermsAndCond",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/TermsAndCond.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/Contact",
    name: "Contact",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Contact.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/Events",
    name: "Events",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Events.vue"),
    meta: { requireAuth: false },
  },
  // {
  //   path: "/TechCorner",
  //   name: "TechCorner",
  //   //component: Dashboard
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   component: () =>
  //     import(/* webpackChunkName: "Dashboard" */ "../views/TechCorner.vue"),
  //   meta: { requireAuth: false },
  // },
  {
    path: "/Links",
    name: "Links",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Links.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/Login",
    name: "Login",
    //component: Dashboard
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Login.vue"),
    meta: { requireAuth: false },
  },
  // {
  //   path: "/IRDashboard",
  //   name: "IRDashboard",
  //   component: () =>
  //     import(/* webpackChunkName: "Dashboard" */ "../views/IRDashboard.vue"),
  //   meta: { requireAuth: true },
  // },
  // {
  //   path: "/IRRegister",
  //   name: "IRRegister",
  //   component: () =>
  //     import(/* webpackChunkName: "Dashboard" */ "../views/IRRegister.vue"),
  //   meta: { requireAuth: false },
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if(to.path == "/logout"){
    if (store.getters.getIRToken) {
      store.commit('removeAuth')
      // console.log("Loggin out");
      // next({name: "Home"})
    } else {
      // console.log(store.getters.getToken)
      // console.log("Not logged in");
      next({ name: "Login" });
    }
  } 
  else if(to.meta.requireAuth) {
    if (store.getters.getIRToken) {
      // console.log("Token found");
      next();
    } else {
      // console.log(store.getters.getToken)
      // console.log("nub");
      next({ name: "Login" });
    }
  }
  else{
    
      next();
  
    //console.log("niets nodig");
    
  }
});

export default router
