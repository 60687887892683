<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
 
<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  mounted() {

  },
};
</script>
<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Poppins);
.v-application {
   font-family:Poppins;
   max-height: 100vh;
   max-width: 100%;
 }

</style>