<template>
  <div class="wrap">
    <div class="home hidden-md-and-down">
      <!-- <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Click Me
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>
  
          <v-card-text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- <eventextrainfo :visible="elfstedentocht" @close="elfstedentocht=false"/> -->
      <v-row class="hidden-md-and-down" width="70%">
        <v-col cols="3">
          <!-- <v-card
            elevation="5"
            class="pa-3 mx-5"
            justify-self="center"
            style="
              max-width: 50vh;
              min-width: 30vh;
              max-height: 80vh;
              min-height: 20vh;
            "
          >
            <v-card-title
              >Notice</v-card-title>
            <v-card-text>
               link to https://drive.google.com/file/d/13ZH-aSTlgmZIf5XQ51zx7W1aCV9e_s10/view?usp=sharing  
              <p>
                <a href="https://drive.google.com/file/d/13ZH-aSTlgmZIf5XQ51zx7W1aCV9e_s10/view?usp=sharing">
                  <v-btn
                    color="primary"
                    text
                  >
                    GPX Files
                  </v-btn>
                </a>

              </p>
              
            </v-card-text>
          </v-card> -->
          <v-card
            elevation="5"
            class="py-3 mx-5"
            justify-self="center"
            style="
              max-width: 36vh;
              min-width: 23vh;
              max-height: 30vh;
              min-height: 10vh;
            "
          >
            <v-card-title style="font-size: 1.2em"
              >Want to register?</v-card-title
            >
            <v-card-actions>
              <div style="margin-top: -2.5vh; padding-left: 6vh">
                <v-btn
                  style="font-size: 0.8em"
                  icon
                  href="/Register"
                >
                  <v-icon>window</v-icon> Click here!
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="7">
        </v-col>
        <v-col cols="1">
          <v-card
            elevation="5"
            class="pa-4"
            justify-self="center"
            style="
              max-width: 30vh;
              min-width: 30vh;
              max-height: 35vh;
              min-height: 10vh;
            "
          >
            <v-card-title style="font-size: 1.5em">Some pictures</v-card-title>
            <v-carousel
              :continuous="true"
              :cycle="cycle"
              :show-arrows="true"
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              height="100%"
              width="100%"
            >
              <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-img aspect-ratio="1.2" :src="slide"> </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        <!-- <v-col cols="1"></v-col> -->
      </v-row>
      <v-row class="hidden-md-and-down" width="70%">
        <v-col cols="1">
          
        </v-col>
        <v-col cols="8"> </v-col>
        <v-col cols="2" style="margin-left: 7.4%">
          <v-card
            elevation="5"
            class="py-3 mx-5"
            justify-self="center"
            style="
              max-width: 38vh;
              min-width: 30vh;
              max-height: 30vh;
              min-height: 10vh;
            "
          >
            <v-card-title style="font-size: 1.2em"
              >Our social media</v-card-title
            >
            <v-card-actions>
              <div style="margin-top: -3.5vh; padding-left: 2vh">
                <a
                  href="https://www.instagram.com/bsa_oc_belgium/"
                  target="_blank"
                >
                  <v-avatar class="mx-1 mt-5" size="40" tile>
                    <v-img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/2048px-Instagram_logo_2022.svg.png"
                    >
                    </v-img>
                  </v-avatar>
                </a>
                <a
                  href="https://www.facebook.com/BSAOCBelgium/"
                  target="_blank"
                >
                  <v-avatar class="mx-1 mt-5" size="40" tile>
                    <v-img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/1200px-Facebook_f_logo_%282019%29.svg.png"
                    >
                    </v-img>
                  </v-avatar>
                </a>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </div>
    <div class="homephone hidden-lg-and-up">
      <v-carousel
        :continuous="false"
        :cycle="false"
        :show-arrows="true"
        hide-delimiters
      >
        <v-carousel-item>
          <v-row class="hidden-lg-and-up" width="70%" style="margin-top: 90%">
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <v-snackbar color="primary secondary--text" v-model="snackbar">
                Swipe left to see more!

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="secondary"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </v-carousel-item>
        <v-carousel-item>
          <v-row class="hidden-lg-and-up" width="70%">
            <v-card
              elevation="5"
              color="white black--text"
              class="py-0"
              justify-self="center"
              style="
                max-width: 55vh;
                min-width: 40vh;
                max-height: 19vh;
                min-height: 19vh;
                margin-left: 6vh;
              "
            >
              <v-card-title style="padding-left: 2.2vh"
                >Follow us on social media!</v-card-title
              >
              <v-card-actions>
                <div style="margin-top: -2.5vh; padding-left: 5vh">
                  <a
                    href="https://www.instagram.com/bsa_oc_belgium/"
                    target="_blank"
                  >
                    <v-avatar class="mx-5" size="50" tile>
                      <v-img
                        src="https://image.flaticon.com/icons/png/512/2111/2111463.png"
                      >
                      </v-img>
                    </v-avatar>
                  </a>
                  <a
                    href="https://www.facebook.com/BSAOCBelgium/"
                    target="_blank"
                  >
                    <v-avatar class="mx-5" size="50" tile>
                      <v-img
                        src="https://cdn.icon-icons.com/icons2/2429/PNG/512/facebook_logo_icon_147291.png"
                      >
                      </v-img>
                    </v-avatar>
                  </a>
                </div>
              </v-card-actions>
            </v-card>
            <v-card
              elevation="5"
              color="white black--text"
              class="py-0 mt-5"
              justify-self="center"
              style="
                max-width: 35vh;
                min-width: 32vh;
                max-height: 15vh;
                min-height: 15vh;
                margin-left: 20%;
              "
            >
              <v-card-title style="padding-left: 5vh"
                >Want to register?</v-card-title
              >
              <v-card-actions>
                <div
                  style="margin-top: -2.5vh; padding-left: 12vh; color: grey"
                >
                  <v-btn
                    color="grey black--text"
                    icon
                    href="/Register"
                  >
                    <v-icon>window</v-icon> Click here!
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;
  overflow: hidden;
}
.homephone {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background_phone.jpg);
  background-size: cover;
  overflow: hidden;
}
.wrap {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
<script>
// import eventextrainfo from "@/components/eventextrainfo.vue";
const axios = require("axios");
export default {
  name: "HomePage",
  // components: {
  //   eventextrainfo
  // },
  data() {
    return {
      cycle: true,
      elfstedentocht: false,
      slides: [],
      hover: false,
      snackbar: true,
    };
  },
  mounted() {
    var postData = {};
    let axiosConfig = {
      headers: {
        // "auth-token": store.state.token,
      },
    };
    const url = `https://bsaoc.be:8443/albums/list`;
    axios
      .post(url, postData, axiosConfig)
      .then((res) => {
        let Albums = res.data;
        // //console.log(res.data)
        for (let i = 0; i < 5; i++) {
          //Albums[Math.floor(Math.random() * Albums.length)])

          var postData = {
            Album: Albums[Math.floor(Math.random() * Albums.length)].Name,
          };
          //console.log(this.Album)
          let axiosConfig = {
            headers: {
              // "auth-token": store.state.token,
            },
          };
          const url = `https://bsaoc.be:8443/albums/showalbum`;
          axios
            .post(url, postData, axiosConfig)
            .then((res) => {
              // console.log(res.data);
              let Images = res.data;
              let image = Images[Math.floor(Math.random() * Images.length)];

              // console.log('../assets/images/' + image )
              this.slides.push(require("../assets/images/" + image));
              //console.log()
              //Users terug callen om de data te updaten
              // store.dispatch("callUsersAPI");
            })
            .catch((err) => {
              console.log(err);
            });
        }
        // console.log(this.slides)
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
</script>
